'use client';

import { getAccessToken, setCookie } from '@ahm/api-wrappers-core';
import { local } from '@ahm/common-helpers';
import { useSearchParams } from 'next/navigation';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
  type ReactNode,
} from 'react';
import { toast } from 'sonner';
import { useFBLoginMerchant } from '@/app/[locale]/(main)/api/use-facebook-login';
// import { useGetFacebookPages } from '@/app/[locale]/(main)/api/use-get-page-info';
import { useGetUserInfo } from '@/app/[locale]/(main)/api/use-get-user-info';
import { publicEnv } from '@/config/public-env.config.mjs';

export type FBuser = {
  _id: string;
  email: string;
  status: string;
  name: string;
  facebook_id: string;
  facebook_token: string;
  create_time: number;
};

export interface PageInfo {
  data: PageData[];
  paging: Paging;
}

export interface PageData {
  map: any;
  access_token: string;
  category: string;
  category_list: CategoryList[];
  name: string;
  id: string;
  tasks: string[];
}

export interface CategoryList {
  id: string;
  name: string;
}

export interface Paging {
  cursors: Cursors;
}

export interface Cursors {
  before: string;
  after: string;
}
interface AuthState {
  // pageInfo: PageInfo | null;
  accessToken: string | null;
  user: FBuser | null;
  isAuthorized: 'authorized' | 'unauthorized' | 'loading';
  isLoadingState: boolean;
  login: () => void;
  logout: () => void;
}

const AuthContext = createContext<AuthState | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [accessToken, setAccessToken] = useState<string | null>(
    getAccessToken()
  );

  // const [pageInfo, setPageInfo] = useState<PageInfo | null>(null);
  const [user, setUser] = useState<FBuser | null>(null);
  const [isLoadingState, setIsLoadingState] = useState<boolean>(false);
  const [isAuthorized, setIsAuthorized] =
    useState<AuthState['isAuthorized']>('loading');

  const searchParams = useSearchParams();
  const sessionToken = searchParams.get('session_token');

  const { data: userData, isLoading: isGettingInfo } = useGetUserInfo({
    queryKey: ['userInfor', accessToken],
    enabled: !!accessToken,
  });
  const { mutate: mutateFBLogin, isPending: isLoggingIn } = useFBLoginMerchant({
    onSuccess(res) {
      setCookie('token', res.access_token);
      setAccessToken(res.access_token);
      setIsAuthorized('authorized');
      toast.success('Liên kết Facebook thành công');
      clearDataFromUrl(['session_token']);
    },
    onError() {
      toast.error('Không thể đăng nhập ở thời điểm này, vui lòng thử lại sau.');
      setIsAuthorized('unauthorized');
      clearDataFromUrl(['session_token']);
    },
  });

  useEffect(() => {
    if (userData) {
      setIsAuthorized('authorized');
      setUser(userData);
      localStorage.setItem('user', JSON.stringify(userData));
    }
  }, [userData]);

  useEffect(() => {
    if (isLoggingIn || isGettingInfo) {
      setIsLoadingState(true);
    } else {
      setIsLoadingState(false);
    }
  }, [isGettingInfo, isLoggingIn]);
  function clearDataFromUrl(fields: string[]) {
    try {
      const url = new URL(window.location.href);
      fields.forEach((field) => url.searchParams.delete(field));
      window.history.replaceState({}, '', url.toString());
    } catch (error) {
      console.error('Failed to clear error code and message from url', error);
    }
  }

  const login = useCallback(() => {
    const scopes = [
      'email',
      'pages_messaging',
      'pages_show_list',
      'pages_read_user_content',
      'pages_manage_ads',
      'pages_manage_engagement',
    ];

    const fbLoginUrl = `https://www.facebook.com/${publicEnv.NEXT_PUBLIC_FACEBOOK_API_VERSION}/dialog/oauth?client_id=${publicEnv.NEXT_PUBLIC_FACEBOOK_APP_ID}&redirect_uri=${publicEnv.NEXT_PUBLIC_APP_URI}/api/auth/callback/facebook&scope=${scopes.join(',')}&response_type=code&auth_type=rerequest&state={}`;
    window.open(fbLoginUrl, '_self');
  }, []);

  const logout = useCallback(() => {
    localStorage.removeItem('user');
    localStorage.removeItem('page');
    setCookie('token', '', { expires: new Date(0) });
    setAccessToken(null);
    setUser(null);
    localStorage.removeItem('fb.token');
    setIsAuthorized('unauthorized');
    clearDataFromUrl(['session_token']);
  }, []);

  useEffect(() => {
    if (sessionToken) {
      mutateFBLogin({
        facebook_token: sessionToken,
      });
      local.setItem('fb.token', sessionToken);
    }
    setIsAuthorized('unauthorized');
  }, [sessionToken, mutateFBLogin]);

  return (
    <AuthContext.Provider
      value={{
        // pageInfo,
        isLoadingState,
        accessToken,
        user,
        isAuthorized,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
