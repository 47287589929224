'use client';

import { FacebookWhite } from '@ahamove/icons';
import { cn } from '@ahm/common-helpers';
import { Button } from '@ahm/ui/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@ahm/ui/components/ui/dropdown-menu';

import { ChevronDown, Loader2, LogOut } from 'lucide-react';
import Link from 'next/link';
import { usePathname } from '@/navigation';
import { useAuth } from '@/providers/auth-provider';

export default function AuthButton() {
  const pathname = usePathname();
  const { isAuthorized, isLoadingState, user, login, logout } = useAuth();
  if (isAuthorized === 'authorized') {
    return (
      <div className="flex items-center justify-center space-x-6">
        <Link passHref href="/pages">
          <Button
            variant="link"
            className={cn(
              'h-[50px] w-full rounded bg-transparent  text-xl font-bold !no-underline hover:bg-transparent md:px-12',
              {
                'hover:text-neutral-70 text-neutral-50': pathname !== '/pages',
                'text-primary-60': pathname === '/pages',
              }
            )}
            disabled={isLoadingState}
          >
            Trang của bạn
          </Button>
        </Link>
        <DropdownMenu>
          <DropdownMenuTrigger
            asChild
            className="hover:text-neutral-70 active:text-neutral-70 focus:text-neutral-70 text-neutral-50"
          >
            <div className="flex py-4">
              <span className="w-full max-w-96 self-center truncate bg-transparent text-xl font-bold">
                Hi, {user?.name}
              </span>

              <ChevronDown className="ml-2 size-6 flex-none" />
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem onClick={logout} className="font-medium">
              <LogOut className="mr-2 size-4" />
              Đăng xuất
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    );
  }
  return (
    <Button
      variant={
        isLoadingState || isAuthorized === 'loading' ? 'secondary' : 'default'
      }
      className="h-[60px] w-full min-w-52 rounded px-12 text-xl font-bold"
      onClick={login}
      disabled={isLoadingState || isAuthorized === 'loading'}
    >
      {isLoadingState || isAuthorized === 'loading' ? (
        <Loader2 size={24} className="animate-spin text-neutral-500" />
      ) : (
        <>
          <FacebookWhite className="mr-2 size-6" /> Đăng nhập
        </>
      )}
    </Button>
  );
}
