/* ISC License (ISC). Copyright 2017 Michal Zalecki */

/**
 * How to use
 *
 * import * as storage from './storage';
 *
 * function login(token) {
 *  storage.local.setItem('token', token);
 *  // do your other login things
 * }
 *
 */
export function storageFactory(
  getStorage: () => Storage | null | undefined
): Storage {
  let inMemoryStorage: Record<string, string> = {};
  let isStorageSupported: boolean | undefined;

  function isSupported(): boolean {
    if (isStorageSupported === undefined) {
      try {
        const storage = getStorage();
        if (!storage) {
          throw new Error('Storage not available');
        }

        const testKey = '__local_test_key__';
        storage.setItem(testKey, testKey);
        storage.removeItem(testKey);
        isStorageSupported = true;
      } catch (e) {
        isStorageSupported = false;
      }
    }
    return isStorageSupported;
  }

  function clear(): void {
    const storage = getStorage();
    if (isSupported() && storage) {
      storage.clear();
    } else {
      inMemoryStorage = {};
    }
  }

  function getItem(name: string): string | null {
    const storage = getStorage();
    if (isSupported() && storage) {
      return storage.getItem(name);
    } else {
      return inMemoryStorage[name] ?? null;
    }
  }

  function key(index: number): string | null {
    const storage = getStorage();
    if (isSupported() && storage) {
      return storage.key(index);
    } else {
      return Object.keys(inMemoryStorage)[index] ?? null;
    }
  }

  function removeItem(name: string): void {
    const storage = getStorage();
    if (isSupported() && storage) {
      storage.removeItem(name);
    } else {
      delete inMemoryStorage[name];
    }
  }

  function setItem(name: string, value: string): void {
    const storage = getStorage();
    if (isSupported() && storage) {
      storage.setItem(name, value);
    } else {
      inMemoryStorage[name] = value;
    }
  }

  function length(): number {
    const storage = getStorage();
    if (isSupported() && storage) {
      return storage.length;
    } else {
      return Object.keys(inMemoryStorage).length;
    }
  }

  return {
    getItem,
    setItem,
    removeItem,
    clear,
    key,
    get length() {
      return length();
    },
  };
}

export const local = storageFactory(() =>
  typeof localStorage !== 'undefined' ? localStorage : null
);
export const session = storageFactory(() =>
  typeof sessionStorage !== 'undefined' ? sessionStorage : null
);
