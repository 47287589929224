import { notFound } from 'next/navigation';
import type { IntlConfig } from 'next-intl';
import type { Pathnames } from 'next-intl/navigation';
import { getRequestConfig } from 'next-intl/server';

type RequestConfig = Omit<IntlConfig, 'locale'>;

export const locales = ['vi', 'en'];

// Use locale can without a prefix
export const localePrefix = 'as-needed';

export const localeDetection = false;

export const defaultLocale = 'vi';

export const pathnames = {
  '/': '/',
} satisfies Pathnames<typeof locales>;

export type AppPathnames = keyof typeof pathnames;

const i18n = getRequestConfig(async ({ locale }) => {
  // Validate that the incoming `locale` parameter is valid
  if (!locales.includes(locale)) notFound();

  return {
    // timeZone: 'Asia/Ho_Chi_Minh',
    // messages: (await import(`@ahm/common-i18n/locales/vi`)).default,
    // messages: (await import(`../../../packages/common-i18n/src/locales/${locale}`)).default,
    messages: (
      await (locale === 'vi'
        ? // When using Turbopack, this will enable HMR for `vi`
          import('@ahm/common-i18n/locales/vi')
        : import(`../../../packages/common-i18n/src/locales/${locale}`))
    ).default,
  };
}) as RequestConfig | Promise<RequestConfig>;

export default i18n;
