import { jsx as e } from "react/jsx-runtime";
const o = (h) => /* @__PURE__ */ e(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: "1rem",
    height: "1rem",
    fill: "none",
    viewBox: "0 0 24 24",
    ...h,
    children: /* @__PURE__ */ e(
      "path",
      {
        fill: "#fff",
        fillRule: "evenodd",
        d: "M19 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h7v-7h-2v-3h2V8.5A3.5 3.5 0 0 1 15.5 5H18v3h-2a1 1 0 0 0-1 1v2h3v3h-3v7h4a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2",
        clipRule: "evenodd"
      }
    )
  }
), l = o;
export {
  l as default
};
